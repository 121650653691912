import Layout from "./Layout";
import { useEffect, useState } from "react";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import axios from "../../../shared/config/axios-interceptor";
import { IHistory } from "@/shared/model/history.model";
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";
import { mapBiDatCuocGroup } from "@/shared/enumeration/ResponseStatus";
import { formatVND } from "@/shared/utils/ultils";
import { useTranslation } from "react-i18next";
const HistoryOrder = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();
  const { user } = useSelector((state: RootState) => state.authentication);

  const { navigate } = useRouter();

  const [historys, setHistorys] = useState<IHistory[] | []>([]);

  const getHistory = async () => {
    try {
      const response = await axios.get(
        "/history?state=SHOW&sortOrder=DESC&sortBy=historyId&limit=30&userId=" +
          user?.id
      );
      setHistorys(response.data.data);
    } catch (error) {
      setHistorys([]);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getHistory();
  }, []);

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            {t("Đã trúng thưởng")}: {formatVND(item.addMoney ?? 0)}
          </div>
        );
      }
      return <div className="text-danger">{t("Không trúng thưởng")}</div>;
    }
    return <div className="text-warning">{t("Chưa mở thưởng")}</div>;
  };

  return (
    <Layout>
      <div className="box-history-order-wap">
        <div className="box-header">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h4 className="text-white text-center mb-0">{t("Lịch sử")}</h4>
        </div>

        <div className="box-history">
          <div className="box-list pb-4">
            {historys?.map((i: IHistory, index: number) => {
              return (
                <div className="item mt-2" key={index}>
                  <div className="header-item px-3 py-2 d-flex justify-content-between">
                    <div className="title">
                      {t(i.biDatCuoc.game?.name ?? "")}
                    </div>
                    <div className="time">
                      {t("Kỳ")}: {i.KyId}
                    </div>
                  </div>
                  <div className="content-item px-3 py-2">
                    <div className="item">
                      <div className="name mb-3">
                        {t(mapBiDatCuocGroup[i?.biDatCuoc?.group ?? 1])} -{" "}
                        {t(i.biDatCuoc.name)}
                      </div>
                      <div className="rate mb-2 d-flex justify-content-between">
                        <div>
                          {t("Đặt cược")}: {formatVND(i?.money ?? 0)}
                        </div>
                        {renderKQ(i)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HistoryOrder;
