export enum Language {
  vi = "vi", //Việt Nam
  en = "en", //English
  zh = "zh", // Trung Quốc,
}

export const languageArray: Language[] = [
  Language.vi,
  Language.en,
  Language.zh,
];

export const mapLanguage: { [key in Language]: string } = {
  [Language.vi]: "Tiếng Việt",
  [Language.en]: "Tiếng Anh",
  [Language.zh]: "Tiếng Trung",
};
