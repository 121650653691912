import { useRouter } from "@/shared/utils/hooks/useRouter";
import { useEffect, useState } from "react";
import Layout from "./Layout";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react-pro";
import { ITransaction } from "@/shared/model/transaction.model";
import axios from "../../../shared/config/axios-interceptor";
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  ResponseStatusTransaction,
  mapResponseStatusToColorTransaction,
  mapResponseStatusTransaction,
} from "@/shared/enumeration/ResponseStatus";
import { formatUSD } from "@/shared/utils/ultils";
import { useTranslation } from "react-i18next";

const HistoryWithdraw = () => {
  const { navigate } = useRouter();
  const { user } = useSelector((state: RootState) => state.authentication);
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();
  const [data, setData] = useState<ITransaction[] | []>([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        "/transaction?type=2&sortOrder=DESC&sortBy=id&limit=20&userId=" +
          user?.id
      );
      setData(response.data.data);
    } catch (error) {
      setData([]);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className="box-history-order box-history-order-wap">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">
            {t("Lịch sử rút tiền")}
          </h5>
        </div>

        <div className="box-history">
          {data.map((item, index) => {
            return (
              <div className="item p-3 mb-2" key={index}>
                <div className="d-flex justify-content-between">
                  <div style={{ color: "#000", fontSize: 14 }}>
                    {t("Rút tiền")}:{" "}
                    <span style={{ color: "#00be6e" }}>
                      {formatUSD(item?.money || 0)}
                    </span>
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    className={
                      "fw-bold text-" +
                      mapResponseStatusToColorTransaction[item?.status]
                    }
                  >
                    {t(mapResponseStatusTransaction[item?.status])}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div style={{ fontSize: 12, marginTop: 4, color: "#888" }}>
                    {dayjs(item.createdDate).format("DD/MM/YYYY HH:mm")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default HistoryWithdraw;
