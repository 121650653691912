import { NavLink } from "react-router-dom";
import home from "../../../assets/img/home.png";
import uuDai from "../../../assets/img/uu-dai.png";
import cskh from "../../../assets/img/cksh.png";
import caNhan from "../../../assets/img/ca-nhan.png";
import { useTranslation } from "react-i18next";
const MenuBottom = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();

  const listMenu = [
    {
      name: "Trang chủ",
      icon: <i className="fa-solid fa-house"></i>,
      path: "/",
    },
    {
      name: "Đại sảnh",
      icon: <i className="fa-solid fa-gamepad"></i>,
      path: "/uudai",
    },
    {
      name: "CSKH",
      icon: <i className="fa-solid fa-headset"></i>,
      path: "/message",
    },
    {
      name: "Cá nhân",
      icon: <i className="fa-solid fa-user"></i>,
      path: "/account",
    },
  ];
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      {listMenu.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className="text-center w-100 d-flex flex-column  align-items-center"
        >
          {item.icon}
          <span>{t(item.name)}</span>
        </NavLink>
      ))}
      {/* <NavLink
        to={"/uudai"}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img width={28} src={uuDai} />
        <span>{t("Đại sảnh")}</span>
      </NavLink>
      <NavLink
        to={"/message"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={30} height={27} src={cskh} />
        <span>{t("CSKH")}</span>
      </NavLink>
      <NavLink
        to={"/account"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={27} src={caNhan} />
        <span>{t("Cá nhân")} </span>
      </NavLink> */}
    </div>
  );
};

export default MenuBottom;
