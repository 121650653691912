import ConfirmDeleteModal from "@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal";
import Edit02Icon from "@/components/shared/icons/Edit02Icon";
import { ToastSuccess } from "@/components/shared/toast/Toast";
import {
  CAvatar,
  CButton,
  CFormInput,
  CFormSwitch,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { ResponseStatus } from "../../../shared/enumeration/ResponseStatus";
import { IBank } from "../../../shared/model/bank.model";
import { createIndexes } from "../../../shared/utils/ultils";
import { AppDispatch } from "../../../store";
import CustomTableFooter from "../../shared/CustomTableFooter/CustomTableFooter";
import FilterIcon from "../../shared/icons/FilterIcon";
import PlusIcon from "../../shared/icons/PlusIcon";
import SearchIcon from "../../shared/icons/SearchIcon";
import TrashIcon from "../../shared/icons/TrashIcon";
import {
  bankSelectors,
  fetching,
  resetEntity,
  setFilterState,
} from "./banksManagement.reducer";
import { getEntities, removeEntity, updateEntity } from "./banksManagement.api";
import BankUpdate from "./BankUpdate";

const columns = [
  {
    key: "bankId",
    label: "ID",
    sorter: false,
  },
  {
    key: "avatar",
    label: "Ảnh đại diện",
    sorter: false,
  },
  {
    key: "name",
    label: "Tên ngân hàng",
    sorter: false,
    _props: { width: "20%" },
  },
  {
    key: "status",
    label: "Tình trạng",
    sorter: false,
    _props: { width: "20%" },
  },
  {
    key: "actions",
    label: "Thao tác",
    sorter: false,
    _props: { className: "text-center" },
  },
];

interface IIndexBank extends IBank {
  index: number;
}

const BankManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector(
    (state: RootState) => state.banksReducer
  );
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState;
  const [createBank, setCreateBank] = useState<boolean>(false);
  const [updateBank, setUpdateBank] = useState<IBank | null>(null);

  const handleCreateBank = () => {
    setCreateBank(true);
  };

  const handleUpdateBank = (bank: IBank) => () => {
    setUpdateBank(bank);
  };

  const handleCancelUpdateBank = (update?: boolean) => {
    setUpdateBank(null);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const banks = useSelector(bankSelectors.selectAll);
  const indexedBank = createIndexes(banks, filterState);

  const handleUpdateStatus = (bank: IBank) => () => {
    const status =
      bank.status === ResponseStatus.ACTIVE
        ? ResponseStatus.INACTIVE
        : ResponseStatus.ACTIVE;
    dispatch(fetching());
    dispatch(updateEntity({ ...bank, status }));
  };

  useEffect(() => {
    if (updateEntitySuccess && !createBank && !updateBank) {
      ToastSuccess("Cập nhật trạng thái thành công");
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess("Xoá ngân hàng thành công");
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess]);

  const [chosenDelete, setChosenDelete] = useState<IBank | null>(null);

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.bankId));
      setChosenDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setChosenDelete(null);
  };

  const [keyword, setKeyword] = useState<string>("");

  const onSearch = () => {
    dispatch(getEntities({ ...filterState, keyword }));
  };

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          {/* <div className='d-flex'>
            <CFormInput value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="ID hoặc Tên đăng nhập" />
            <CButton style={{ height: 42, whiteSpace: 'nowrap' }} className="ms-2 btn-custom btn-sm text-white" onClick={onSearch}>
              Tìm kiếm
            </CButton>
          </div> */}
          <div>
            <CButton
              className="btn-custom primary-500 btn-sm"
              onClick={handleCreateBank}
            >
              <PlusIcon /> Tạo ngân hàng mới
            </CButton>
          </div>
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput
                placeholder="Tìm kiếm theo tên"
                aria-label="Bankname"
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedBank}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              avatar: ({ index, ...value }: IIndexBank) => (
                <td>
                  <CImage height={100} src={value.avatar} />
                </td>
              ),

              status: ({ index, ...value }: IIndexBank) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                  </div>
                </td>
              ),

              actions: ({ index, ...value }: IIndexBank) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateBank(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {value.status !== ResponseStatus.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ""
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: "middle",
              responsive: "lg",
              className: "custom-table",
            }}
            tableHeadProps={{
              className: "table-head",
            }}
            tableBodyProps={{
              className: "table-body",
            }}
          />
        </div>

        {createBank ? (
          <BankUpdate visible={createBank} setVisible={setCreateBank} />
        ) : (
          ""
        )}
        {updateBank ? (
          <BankUpdate
            visible={Boolean(updateBank)}
            bankObj={updateBank}
            setVisible={handleCancelUpdateBank}
          />
        ) : (
          ""
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá ngân hàng khỏi hệ thống"
          content={
            <>
              Xác nhận xoá ngân hàng{" "}
              <span className="high-light">{chosenDelete.name}</span> khỏi hệ
              thống?
            </>
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default BankManagement;
