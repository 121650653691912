import { CCarousel, CCarouselItem, CImage, CRow } from "@coreui/react-pro";
import Layout from "./Layout";
import Marquee from "react-fast-marquee";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { formatVND } from "@/shared/utils/ultils";
import { useEffect, useState } from "react";
import logoWeb from "../../../assets/img/logo-9.gif";
import napTien1 from "../../../assets/img/naptien-1.png";
import rutTien1 from "../../../assets/img/rut-tien-1.png";
import lichSu1 from "../../../assets/img/lich-su-1.png";
import box21 from "../../../assets/img/box2-1.png";
import box22 from "../../../assets/img/box2-2.png";
import box23 from "../../../assets/img/box2-3.png";
import box24 from "../../../assets/img/box2-4.png";
import box25 from "../../../assets/img/box2-5.png";
import bot1 from "../../../assets/img/bot1.png";
import { useRouter } from "@/shared/utils/hooks/useRouter";

import bn1 from "../../../assets/img/banner/1.jpg";
import bn2 from "../../../assets/img/banner/2.jpg";
import bn3 from "../../../assets/img/banner/3.jpg";
import bn4 from "../../../assets/img/banner/4.jpg";
import bn5 from "../../../assets/img/banner/5.jpg";
import bn6 from "../../../assets/img/banner/6.jpg";
import bn7 from "../../../assets/img/banner/7.jpg";
import bn8 from "../../../assets/img/banner/8.jpg";
import dayjs from "dayjs";
import avatar from "../../../assets/img/12345.jpg";
import { useTranslation } from "react-i18next";

const HomeWap = () => {
  const { navigate } = useRouter();
  const { user } = useSelector((state: RootState) => state.authentication);

  const { t }: { t: (key: string, options?: any) => string } = useTranslation();

  const [data, setData] = useState<any>(dayjs().format("DD/MM/YYYY HH:mm:ss"));

  useEffect(() => {
    const a = setInterval(() => {
      setData(dayjs().format("DD/MM/YYYY HH:mm:ss"));
    }, 1000);

    return () => clearInterval(a);
  }, []);

  return (
    <div className="home-wap">
      <Layout>
        <div className="home_top">
          <div className="w-100" style={{ color: "#fff" }}>
            {t("Số dư")}: {formatVND(user?.money ?? 0)}
          </div>
          <div className="w-100 home_top-left text-center">
            <div className="home_top-logo soc">
              <img height={70} src={logoWeb} alt="logo" />
            </div>
          </div>
          <div className="w-100 home_top-right text-end">
            <div
              onClick={() => navigate("/account")}
              className="home_top-kf icon-kf"
              style={{ color: "#fff" }}
            >
              {user?.username}
            </div>
          </div>
        </div>

        <div className="main" style={{ fontSize: 14 }}>
          <div className="banner-slide">
            <CCarousel controls interval={2000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn2} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn3} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn4} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn5} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn6} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn7} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={bn8} alt="slide 1" />
              </CCarouselItem>
            </CCarousel>
          </div>

          <div className="box-2-wap">
            <div>
              <div className="border-bottom pb-3">
                <div
                  className="text-center mb-3"
                  style={{ color: "#eb0021", fontSize: 20 }}
                >
                  {t("Chào mừng bạn đến với")}
                </div>
                <div
                  className="text-center fw-bold"
                  style={{ fontSize: 14, color: "#222222" }}
                >
                  {t("MGM MACAU")}
                </div>
                <div className="timenow text-center" style={{ marginTop: 10 }}>
                  {data}
                </div>
              </div>
              <div className="d-flex gap-2 mt-3 px-2">
                <div style={{ color: "#222222", whiteSpace: "nowrap" }}>
                  {t("Công bố")}
                </div>
                <div>
                  <Marquee>
                    <div>{t("Hi，Chào Mừng bạn MGM CASINO")}</div>
                  </Marquee>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="d-flex">
                <div
                  onClick={() => navigate("/uudai")}
                  className="w-100 d-flex gap-2 align-items-center flex-column"
                >
                  <img width={50} height={50} src={logoWeb} />
                  <div
                    style={{ color: "#333", fontSize: 14 }}
                    className="fw-bold text-center"
                  >
                    PKHOT 5P
                  </div>
                </div>

                <div
                  onClick={() => navigate("/message")}
                  className="w-100 d-flex gap-2 align-items-center flex-column"
                >
                  <img width={50} height={50} src={napTien1} />
                  <div
                    style={{ color: "#333", fontSize: 14 }}
                    className="fw-bold text-center"
                  >
                    {t("Nạp tiền")}
                  </div>
                </div>

                <div
                  onClick={() => navigate("/withdraw")}
                  className="w-100 d-flex gap-2 align-items-center flex-column"
                >
                  <img width={50} height={50} src={rutTien1} />
                  <div
                    style={{ color: "#333", fontSize: 14 }}
                    className="fw-bold text-center"
                  >
                    {t("Rút tiền")}
                  </div>
                </div>

                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={lichSu1} />
                  <div
                    style={{ color: "#333", fontSize: 14 }}
                    className="fw-bold text-center"
                  >
                    Lịch sử
                  </div>
                </div>
              </div>

              <div className="slogan-1 px-3 mt-3">
                <Marquee>
                  <div>
                    【1 {t("phút")} 】aeiop2 2xx00 đ 【5 {t("phút")}】terepcf
                    1xx30 đ 【1
                    {t("phút")}】yui6g 8x80 đ 【1 {t("phút")}】qwerfux 2xx29 đ
                    【5
                    {t("phút")}】dcdcvc9 2xx99 đ 【5 {t("phút")}】sheni6 5xx58 đ
                    【1
                    {t("phút")}】shab9le 7xx82 đ 【5 {t("phút")}】zhuc0 6xx65 đ
                    【1
                    {t("phút")}】jimosxi 9xx35 đ 【5 {t("phút")}】zee88b 1xx91 đ
                    【1
                    {t("phút")}】demody 4xx23 đ 【5 {t("phút")}】wlgc82 9xx14 đ
                    【1
                    {t("phút")}】zent4j5 4xx84 đ 【5 {t("phút")}】zant0jz 8xx97
                    đ 【1
                    {t("phút")}】zhai39l 7xx26 đ 【5 {t("phút")}】zeeak 2xx98 đ
                    【3
                    {t("phút")}】qpal6wr 1xx18 đ 【1 {t("phút")}】5sinke 8xx30 đ
                    【5
                    {t("phút")}】aini3c 5xx35 đ 【1 {t("phút")}】kunlpxa 6xx8 đ
                  </div>
                </Marquee>
              </div>
            </div>
          </div>

          <div className="box-2-wap mt-3">
            <div
              className="px-3 pt-2 sap-ra-mat text-center"
              style={{ color: "#222222" }}
            >
              {t("TRÒ CHƠI SẮP RA MẮT")}
            </div>

            <div className="mt-3">
              <div className="d-flex">
                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={avatar} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      MGM CA SINO
                    </div>
                    <span style={{ fontSize: 12 }}> 5 phút</span>
                  </div>
                </div>

                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={box21} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      {t("Xem thêm")}
                    </div>
                    <span style={{ fontSize: 12 }}>{t("Xem thêm")}</span>
                  </div>
                </div>

                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={box22} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      {t("Sự phân lẻ")}
                    </div>
                    <span style={{ fontSize: 12 }}>{t("Sự phân lẻ")}</span>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={box23} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      {t("Năm điểm")}
                    </div>
                    <span style={{ fontSize: 12 }}>{t("Năm điểm")}</span>
                  </div>
                </div>

                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={box24} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      {t("5 out of 11")}
                    </div>
                    <span style={{ fontSize: 12 }}>{t("5 out of 11")}</span>
                  </div>
                </div>

                <div className="w-100 d-flex gap-2 align-items-center flex-column">
                  <img width={50} height={50} src={box25} />
                  <div className="text-center">
                    <div
                      style={{ color: "#333", fontSize: 14 }}
                      className="fw-bold"
                    >
                      {t("Hai phút")}
                    </div>
                    <span style={{ fontSize: 12 }}>{t("Hai phút")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ background: "#f8f8f8" }} className="py-3 mt-3">
            <h3 className="fw-bold" style={{ fontSize: 18 }}>
              {t("Hỗ trợ kỹ thuật")}
            </h3>
            <img src={bot1} />

            <div>{t("Lottery lucky hệ thống")}</div>
            <div>{t("Nền tảng hệ thống xổ số chuyên nghiệp")}</div>
            <div className="fw-bold mt-3" style={{ fontSize: 18 }}>
              {t("Kinh nghiệm dịch vụ")}
            </div>
            <div>
              {t("Thời gian trung bình nạp tiền vào tài khoản ngày hôm nay")}
            </div>
            <div>{t("Thời gian trung bình rút tiền và đến ngày hôm nay")}</div>
          </div>

          <div className="mt-5">
            <div className="container text-center">
              <p style={{ fontSize: 15 }}>
                {t("Quan điểm chúng tôi")} | {t("Liên hệ chúng tôi")} |{" "}
                {t("Hợp tác kinh doanh")} |{t("Pháp lý thực tế")} |{" "}
                {t("Cam kết bảo mật")}
              </p>
              <p style={{ fontSize: 15 }}>
                {t("Copyright © Lottery lucky")} | 18+
              </p>
            </div>
          </div>

          {/* <div className="box-info my-3 p-2">
            <div className="border-bottom  pb-2 mb-2 d-flex justify-content-between  align-items-center">
              <div className="d-flex align-items-center gap-2">
                <CImage
                  width={50}
                  height={50}
                  className="d-block avatar"
                  src={banner1}
                  alt="slide 1"
                />
                <div>
                  <div style={{ fontSize: 13 }}>
                    Biệt danh: {user?.username}
                  </div>
                  <div style={{ fontSize: 13 }}>
                    Số dư: {formatVND(user?.money ?? 0)}
                  </div>
                </div>
              </div>

              <div className="d-flex gap-2">
                <NavLink
                  to={'/message'}
                  style={{ fontSize: 13, background: '#f08a7b', color: '#fff' }}
                  className="btn"
                >
                  Nạp tiền
                </NavLink>
                <NavLink
                  to={'/withdraw'}
                  style={{ fontSize: 13, background: '#c87ac8', color: '#fff' }}
                  className="btn"
                >
                  Rút tiền
                </NavLink>
              </div>
            </div>

            <div className="d-flex gap-2 pb-1 align-items-center">
              <img
                width={20}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAdCAYAAADLnm6HAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASTSURBVHgB5Vc9bFtVFD7nXhsTWglngTLZRWJgwpXYcVgYGBxLqFJEUewMQArIZWFgqJMRFlokFP6q14qWDAicDmW1uyPFmRgYbH7EhBQPJbSO3z2cc+99f8VOk3ZAVU8cv/fuz/n5znfPeQZ41AXhAeWPS9caStELCPriM82lIRxRcnCf8vtXm1VUECBSWZ4JQ7m8D0eUIzsw2Ngso84FBOYlIIOKPQAi1AhFuA85tAODoFOc7E3OEWCLDRc5eYTyxRfFieRxmrX3n05QpnC/qwBGBZ1fwHpzFM2pwxj/+dPvG+EeDTjaNpsqCnGIo2bjcsNPSOoANt1mw8D5IkWVfRh303Pxtp8++nFRI9YQQlDagGLXGFZAbcpIpqo0h4oMuSJQSCRXAIOar2jHIDjReH1llhO7mxvluYLqst4S27iYXzxr+YLbbYa2MNdVmiqsjI3wB8UBMcIQsVFJsxiUca1iRzjqeK0EGJxYThzYvRZUQN0ezS+tDqOxW53PKnnIbStl2PfJQr7+Xk+Fjx/7hHGoODQpAZLAZhb8lx1gTyTnyRI/iZDJ/1/fBos8tq2w0N0Ngpicx+vv9HnpBbtFq7ZclSGq2QFESJcFG7Zojp2KSUaxWVHg12HKeX0Henzp83859wS00s7lobAuAPGOKt34uiQoOlLBdBKLYxEUYE3ZT+IB/LeazTeZ5aGvCRS20ijYE0B406F2p8HZIKtNYnBRR7YoBjZGAWMk0LLfAuT2330M599o9nigxxHMF46Fy+k5ovA6yQYDFWUjtKZYkTWUpoHxhi0K7uiRP/5M/cgx4oKEKW7E0Src8jypZCYey/etTTIVlaCIlMEyyqy/F2OIHiUxnCbsjJZiKPzVe1ZKj+fH4UgQ4KNUVl6/fGOc60inCzu+dxGTRcRWQu8QzqqCRltDMENEtXXAU4qSxTG90PEiGXXmbRFEm0fn1HQjKrTkM1Om3AYaqYT/sRJysIluQRs8MhK1r77+L43YtF6gSNccSXAnPT7h4+l6iRrIMaQIDkdGF5WzaK1TdA+WB862v7nH+wRVZSeX+K2MY1zyxYYhs6N4wU6EiWN5EpM/begwdlOpnFKqOGWrKEgpvrTG82VeP5w7vXIz4xbSoujmCr7FPYTWkyl7JAEy4LqaQI6kyZjLAILnSLob7n4T8MuKPi+LQjNeSBsfdzYarL/ESodYe/e6evHDV7a4vzRZzTCp/VHKkVxa7DFwVPXlCf0pcNy5KxMT6LPtHqA5l25G1AmK7Hdb6gY/rvlQE9n++EZF5/DJnNcirysYhie5Fbe5W9q6jVw7lZQM6ZDSCbUokS5Jl59unGnCAbL3w+cXcgpavG+Qq519VsYyb0SnPni1P2Wf5O/yLxudNkPa9jXR9q4IAUQguIf8/d0Xa7y6xdGP9glfjsYP9UYk8txqfX2MY/baXJFnokwjPvA03Op8WeGqd96SKFTNuXqSlkM7IPL86tLw5Nunm5z1U1znh5S8ERwox+tv9k1IK5y4auG1tzJHEuEB5Df+TaCZVMyDkkbTfGr5zBX4P+TPq1dL8LDKv0Z6IgldBIt7AAAAAElFTkSuQmCC"
              />

              <div style={{ fontSize: 13 }} className="w-100">
                <div className="marquee">
                  <span> Thông báo khẩn cấp </span>
                </div>
              </div>
            </div>
          </div>

          <div className="box-games d-flex gap-2">
            <div className="box-tab">
              <div className="item">
                <img
                  style={{ width: '100%' }}
                  src={tab1}
                  onClick={() => setTab(0)}
                />
              </div>

              {gameType.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <img
                      style={{ width: '100%' }}
                      src={item?.imageMobile ? item?.imageMobile : tab1}
                      onClick={() => setTab(item.id)}
                    />
                  </div>
                )
              })}
            </div>

            <div className="box-game">
              <div
                className="container-box-game"
                style={{ paddingBottom: 200 }}
              >
                {gameType.map((item: IGameType, index) => {
                  return (
                    (tab === 0 || tab === item.id) && (
                      <div className="content1 mb-4" key={index}>
                        <div className="row g-0">
                          {item?.game.map((it, idx) => {
                            return (
                              <div key={idx} className="col-6">
                                <NavLink to={`/game/${it.id}`}>
                                  <CImage
                                    style={{ width: '100%' }}
                                    src={
                                      it.imageMobile ? it.imageMobile : game1
                                    }
                                  />
                                </NavLink>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </div>
  );
};
export default HomeWap;
