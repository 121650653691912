import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import logo from "../../../assets/img/logo8.png";
import { RootState } from "../../../reducers";
import { useRouter } from "../../../shared/utils/hooks/useRouter";
import { AppDispatch } from "../../../store";
import { ToastSuccess } from "../../shared/toast/Toast";
import { ILoginForm, login } from "./auth.api";
import { fetching } from "./auth.reducer";
import { NavLink } from "react-router-dom";
import userLogin from "../../../assets/img/user-login.png";
import passLogin from "../../../assets/img/pass-login.png";

import hiddenPass from "../../../assets/img/hiden-pass.png";
import { languageArray } from "@/shared/enumeration/language";
import { mapLanguage } from "@/shared/enumeration/language";
import showPass from "../../../assets/img/show-pass.png";
import { Language } from "@/shared/enumeration/language";
import { useTranslation } from "react-i18next";
interface ILocationPath {
  path?: string;
}

const LoginWap = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n }: { t: (key: string, options?: any) => string; i18n: any } =
    useTranslation();
  const { navigate, location } = useRouter();
  const state = location.state as ILocationPath;
  const { user, loading, loginSuccess, firstTimeLogin } = useSelector(
    (state: RootState) => state.authentication
  );

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required(t("Không được để trống")),
    password: Yup.string().required(t("Không được để trống")),
  });

  useEffect(() => {
    if (user) {
      const redirectPath = state?.path || "/";
      navigate(redirectPath, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (loginSuccess && !firstTimeLogin) {
      ToastSuccess(t("Đăng nhập thành công"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess]);

  const initialValues: ILoginForm = { username: "", password: "" };

  const [showPassWord, setShowPassWord] = useState<boolean>(false);

  const changeLanguage = (language: Language) => {
    i18n.changeLanguage(language);
  };

  return (
    <CRow className="login-wap-fe m-0 min-vh-100 d-block">
      <div
        className="d-flex align-items-center gap-3"
        style={{
          position: "absolute",
          right: 0,
          top: 10,
          fontSize: 14,
          width: 150,
        }}
      >
        <CFormSelect
          style={{
            fontSize: 14,
            border: "none",
            boxShadow: "none",
            outline: "none",
          }}
          value={i18n.language}
          onChange={(e) => changeLanguage(e.target.value as Language)}
        >
          {languageArray.map((language) => (
            <option key={language} value={language}>
              {t(mapLanguage[language])}
            </option>
          ))}
        </CFormSelect>
      </div>
      <div className="login_box-wrap d-flex justify-content-center align-items-center">
        <div className="logo-login">
          <img src={logo} height={170} alt="vtrack_logo" />
        </div>
      </div>
      <CCol className="box-auto">
        <CRow className="align-items-center">
          <CCol xs={12} className="px-3 mt-3 text-center">
            <h1 style={{ fontSize: 30, fontWeight: 700 }}>
              {t("Hội viên Đăng nhập")}
            </h1>
          </CCol>
          <CCol xs={12} className="px-3">
            <CCard className=" pt-4" style={{ background: "transparent" }}>
              {/* <p className="text-display-bold-sm text-gray-modern-900 mb-5">Đăng nhập</p> */}
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  dispatch(fetching());
                  dispatch(login(values));
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <CForm onSubmit={handleSubmit} className="custom-form">
                    <CRow className="mb-4">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel htmlFor="username">
                            {t("Tài khoản hội viên")}
                          </CFormLabel>
                          <div className="d-flex gap-3 w-100">
                            <img
                              className="ms-4"
                              width={27}
                              height={27}
                              src={userLogin}
                            />
                            <CFormInput
                              value={values.username}
                              onChange={(
                                e: React.FormEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "username",
                                  e.currentTarget.value.trim().toLowerCase()
                                );
                              }}
                              type="text"
                              id="username"
                              name="username"
                              autoComplete="none"
                              placeholder={t("Vui lòng nhập tài khoản")}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow className="mb-3">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel htmlFor="password">
                            {t("Mật khẩu đăng nhập")}
                          </CFormLabel>
                          <div className="d-flex gap-3 w-100 align-items-center">
                            <img
                              className="ms-4"
                              width={27}
                              height={27}
                              src={passLogin}
                            />
                            <CFormInput
                              value={values.password}
                              onChange={handleChange}
                              type={showPassWord ? "text" : "password"}
                              id="password"
                              name="password"
                              autoComplete="none"
                              placeholder={t("Vui lòng nhập mật khẩu")}
                              onBlur={handleBlur}
                            />

                            {!showPassWord && (
                              <img
                                className="me-3"
                                width={25}
                                height={10}
                                src={hiddenPass}
                                onClick={() => setShowPassWord(true)}
                              />
                            )}

                            {showPassWord && (
                              <img
                                className="me-3"
                                width={22}
                                height={16}
                                src={showPass}
                                onClick={() => setShowPassWord(false)}
                              />
                            )}
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow className="m-0 justify-content-end">
                      <CLoadingButton
                        className="btn-custom btn-custom-login  primary-500 mt-3 w-100"
                        type="submit"
                        disabled={loading}
                        loading={loading}
                      >
                        {t("Đăng nhập")}
                      </CLoadingButton>
                    </CRow>

                    <CRow className="mt-3 justify-content-end text-more">
                      <p className="text-dark">
                        {t("Chưa có tài khoản?")}{" "}
                        <NavLink
                          to="/wap/register"
                          className="fw-bold"
                          style={{ color: "#eb0021" }}
                        >
                          {t("Đăng ký ngay")}
                        </NavLink>
                      </p>
                    </CRow>
                  </CForm>
                )}
              </Formik>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  );
};

export default LoginWap;
