import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { RootState } from "@/reducers";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { useSelector } from "react-redux";
import axios from "../../../shared/config/axios-interceptor";
import { useEffect, useState } from "react";
import { formatVND } from "@/shared/utils/ultils";
import { CCol, CRow } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";

export const LaiLo = () => {
  const { navigate } = useRouter();
  const { user } = useSelector((state: RootState) => state.authentication);
  const [data, setData] = useState<any>({});
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();

  const getKetQua = async () => {
    try {
      const response = await axios.get("/history/lai-lo");
      setData(response.data);
    } catch (error) {
      setData([]);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getKetQua();
  }, []);

  return (
    <div
      style={{ background: "#fdecda" }}
      className="box-history-order box-history-order-wap box-account-info-wap d-flex flex-column h-100"
    >
      <div className="box-header d-flex justify-content-center align-items-center">
        <ArrowLeft
          style={{ position: "absolute", left: 16 }}
          onClick={() => navigate(-1)}
        />

        <h5 className="text-white text-center mb-0 mt-0">
          {t("Lãi lỗ hôm nay")}
        </h5>
      </div>

      <div
        className="content-account-info-wap p-0 d-flex flex-column"
        style={{ background: "rgb(253, 236, 218)" }}
      >
        <div className="text-center mt-4">{t("Số tiền lãi lỗ")}</div>
        <div
          className="text-center mt-2"
          style={{ fontSize: 24, color: "#eb0021" }}
        >
          {formatVND(data?.total)}
        </div>

        <div className="mt-4 px-3">
          <CRow>
            <CCol xs={6}>
              <div className="text-center">{t("Tổng tiền đặt")}</div>
              <div
                className="text-center"
                style={{ fontSize: 24, color: "#eb0021" }}
              >
                {formatVND(data?.moneyTotal)}
              </div>
            </CCol>
            <CCol xs={6}>
              <div className="text-center">{t("Tổng tiền trả thưởng")}</div>
              <div
                className="text-center"
                style={{ fontSize: 24, color: "#eb0021" }}
              >
                {formatVND(data?.addMoneyTotal)}
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  );
};
