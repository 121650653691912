import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from "../../../shared/config/axios-interceptor";
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { getProfile } from "../auth/auth.api";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { formatVND } from "@/shared/utils/ultils";
import { useTranslation } from "react-i18next";

const Withdraw = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();
  const { location, navigate } = useRouter();

  const { user } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch<AppDispatch>();

  const [money, setMoney] = useState(user?.money ?? 0);
  const [noBank, setNoBank] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) return;
    setMoney(Number(value));
  };

  useEffect(() => {
    if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
      setNoBank(true);
    }
  }, [user?.cardName, user?.cardNumber, user?.bankName]);

  const onSubmit = async () => {
    if (money <= 0) {
      ToastError(t("Số tiền rút phải lớn hơn 0"));
      return;
    }

    if (money > (user?.money ?? 0)) {
      ToastError(t("Số tiền không hợp lệ"));
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post("/transaction", {
        money,
        type: 2,
      });
      if (response) {
        setLoading(false);
        setMoney(0);
        ToastSuccess(t("Hệ thống đang xử lý. Vui lòng chờ trong ít phút"));
        dispatch(getProfile());
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(getProfile());
    }
  };

  if (noBank) {
    return (
      <Navigate to="/add-bank" replace state={{ path: location.pathname }} />
    );
  }

  return (
    <Layout>
      <div className="box-withdraw box-withdraw-wap">
        <div className="box-header">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="m-0 text-white">{t("Rút tiền")}</h5>
        </div>

        <div className="text-center my-5">
          <div style={{ fontSize: 30, color: "#00be6e" }}>
            {formatVND(user?.money ?? 0)}
          </div>
          <div style={{ fontSize: 14, color: "#333" }}>
            {t("Số dư hiện tại")}
          </div>
        </div>

        <div className="box-content mt-4">
          <h5>
            {t("Giới hạn rút")}:{" "}
            {new Intl.NumberFormat("vi-VN").format(user?.money || 0)}
          </h5>

          <div className="d-flex align-items-center form-withdraw gap-4">
            <label>{t("Số tiền rút")}</label>
            <input
              className="amount"
              type="text"
              onChange={(e) => onChange(e)}
              placeholder={t("Nhập số tiền rút")}
              value={money}
            />
          </div>

          <div className="bank mt-5">
            <div>{t("Thông tin ngân hàng")}</div>
            <div>{user?.bankName}</div>
            <div>{user?.cardNumber}</div>
            <div>{user?.cardName}</div>
          </div>

          <div className="mt-4">
            <CButton
              disabled={loading}
              className="w-100 submit"
              onClick={onSubmit}
            >
              {t("Xác nhận")}
            </CButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Withdraw;
