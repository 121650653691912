import { CCol, CContainer, CRow } from "@coreui/react-pro";
import bot1 from "../../../assets/img/bot1.png";
import { useTranslation } from "react-i18next";
const FooterCustom = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();
  return (
    <div style={{ background: "#f8f8f8" }} className="py-5 mt-3">
      <CContainer>
        <CRow>
          <CCol xs={3}>
            <h3 className="fw-bold" style={{ fontSize: 18 }}>
              {t("Hỗ trợ kỹ thuật")}
            </h3>
            <img src={bot1} />

            <div>{t("Lottery lucky hệ thống")}</div>
            <div>{t("Nền tảng hệ thống xổ số chuyên nghiệp")}</div>
          </CCol>
          <CCol>
            <div className="fw-bold" style={{ fontSize: 18 }}>
              {t("Kinh nghiệm dịch vụ")}
            </div>
            <div>
              {t("Thời gian trung bình nạp tiền vào tài khoản ngày hôm nay")}
            </div>
            <div>{t("Thời gian trung bình rút tiền và đến ngày hôm nay")}</div>
          </CCol>
        </CRow>

        <div className="mt-5">
          <div className="container text-center">
            <p style={{ fontSize: 15 }}>
              {t("Quan điểm chúng tôi")} | {t("Liên hệ chúng tôi")} |{" "}
              {t("Hợp tác kinh doanh")} | {t("Pháp lý thực tế")} |{" "}
              {t("Cam kết bảo mật")}
            </p>
            <p style={{ fontSize: 15 }}>
              {t("Copyright © Lottery lucky")} | {t("18+")}
            </p>
          </div>
        </div>
      </CContainer>
    </div>
  );
};

export default FooterCustom;
