import Header from "./Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { RootState } from "@/reducers";
import Nav from "./Nav";
import dayjs from "dayjs";
import { formatVND } from "@/shared/utils/ultils";
import { historySelectors } from "../HistoryManagement/historyManagement.reducer";
import { getEntities } from "../HistoryManagement/historyManagement.api";
import FooterCustom from "./FooterCustom";
import { HistoryState } from "@/shared/enumeration/historyState";
import { useTranslation } from "react-i18next";

const LichSuDatCuoc = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const historys = useSelector(historySelectors.selectAll);
  const { user } = useSelector((state: RootState) => state.authentication);

  const [modal, setModal] = useState(false);

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer
  );
  const { filterState } = initialState;

  useEffect(() => {
    dispatch(
      getEntities({
        ...filterState,
        sortBy: "historyId",
        sortOrder: "DESC",
        userId: Number(user?.id) ?? 0,
        status: null,
        state: HistoryState.SHOW,
      })
    );
  }, []);

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            {t("Đã trúng thưởng")}: {formatVND(item.addMoney ?? 0)}
          </div>
        );
      }
      return <div className="text-danger">{t("Không trúng thưởng")}</div>;
    }
    return <div className="text-warning">{t("Chưa mở thưởng")}</div>;
  };

  return (
    <div className="container-web page-rut-tien-web page-lich-su-rut-tien-web">
      <Header />
      <div className="content-header">
        <Nav />

        <div className="header-bottom pb-4">
          <div className="top d-flex mx-2">
            <div className="w-25">{t("Loại xổ số")}</div>
            <div className="w-25">{t("Số tiền")}</div>
            <div className="w-25">{t("Lãi lỗ")}</div>
            <div className="w-25">{t("Ngày mua")}</div>
            <div className="w-25">{t("Trạng thái")}</div>
          </div>

          <div className="mx-2">
            {historys.length ? (
              historys?.map((item, index) => {
                return (
                  <div className="d-flex item-content" key={index}>
                    <div className="w-25">
                      {t(item.biDatCuoc.game?.name ?? "")} - {t("kỳ")}:{" "}
                      <span style={{ color: "rgb(238, 48, 71)" }}>
                        {item.KyId}
                      </span>
                    </div>
                    <div className="w-25">{formatVND(item?.money ?? 0)}</div>
                    <div className="w-25">{formatVND(item?.addMoney ?? 0)}</div>
                    <div className="w-25">
                      {dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                    {/* <div className={"w-25 text-" + mapResponseIsWin[item?.isWin ?? 0]} >{mapResponseIsWin[item?.isWin ?? 0]}</div> */}

                    <div className={"w-25 text-"}>{renderKQ(item)}</div>
                  </div>
                );
              })
            ) : (
              <h6 style={{ textAlign: "center", color: "#888", marginTop: 20 }}>
                {t("Chưa có lệnh đặt cược nào")}
              </h6>
            )}
          </div>
        </div>
      </div>
      <div className="page-body-web">{/* <Sidebar /> */}</div>

      <FooterCustom />
    </div>
  );
};

export default LichSuDatCuoc;
