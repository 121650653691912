import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import eng language
import translationEn from "./en/translation.json";

// import vi language
import translationVi from "./vi/translation.json";

// import zh language
import translationZh from "./zh/translation.json";
import { Language } from "../enumeration/language";

export const resources = {
  en: {
    translation: { ...translationEn },
  },
  vi: {
    translation: { ...translationVi },
  },
  zh: {
    translation: { ...translationZh },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Language.en,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
