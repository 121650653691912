import Layout from "./Layout";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro";
import { useState } from "react";
import axios from "../../../shared/config/axios-interceptor";
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { useTranslation } from "react-i18next";
const ChangePassword = () => {
  const { navigate } = useRouter();

  const { user } = useSelector((state: RootState) => state.authentication);

  const { t }: { t: (key: string, options?: any) => string } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (index === 1) {
      setCurrentPassword(value);
    }
    if (index === 2) {
      setNewPassword(value);
    }
    if (index === 3) {
      setConfirmNewPassword(value);
    }
  };

  const onSubmit = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      ToastError(t("Vui lòng nhập đầy đủ thông tin"));
      return;
    }

    try {
      const response = await axios.put("/auth/change-password", {
        currentPassword,
        newPassword,
        confirmNewPassword,
      });
      ToastSuccess(t("Cập nhật mật khẩu thành công"));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <div className="box-add-bank box-change-passwword-wap">
        <div className="box-header">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="m-0 text-white">{t("Đổi mật khẩu")}</h5>
        </div>

        <div className="box-form px-2 mt-3">
          <div className="d-flex form-group-custom">
            <CFormLabel
              htmlFor="cardName"
              className="col-sm-2 col-form-label text-nowrap"
            >
              {t("Mật khẩu cũ")}
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(1, e)}
              type="password"
              id="cardName"
              placeholder={t("Mật khẩu cũ")}
              value={currentPassword}
            />
          </div>
          <div className="d-flex form-group-custom">
            <CFormLabel
              htmlFor="cardNumber"
              className="col-sm-2 col-form-label text-nowrap"
            >
              {t("Mật khẩu mới")}
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(2, e)}
              type="password"
              id="cardNumber"
              placeholder={t("Mật khẩu mới")}
              value={newPassword}
            />
          </div>
          <div className="d-flex form-group-custom">
            <CFormLabel
              htmlFor="bankName"
              className="col-sm-2 col-form-label text-nowrap"
            >
              {t("Nhập lại mật khẩu mới")}
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(3, e)}
              type="password"
              id="bankName"
              placeholder={t("Nhập lại mật khẩu mới")}
              value={confirmNewPassword}
            />
          </div>

          <div className="px-3 mt-4">
            <CButton className="w-100" onClick={onSubmit}>
              {t("Cập nhật")}
            </CButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
